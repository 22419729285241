import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'
import Table from 'react-bootstrap/Table'
import {Alert, ListGroup} from "react-bootstrap";

export default class Index extends React.Component {
    render() {
        return (
            <Layout>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <h1>Tariffavtale
                            </h1>
                            <ul>
                                <li>
                                    <ListGroup defaultActiveKey="#link1">
                                        <ListGroup.Item action href='/pdf/Tariffhefte01012025.pdf'>
                                            Tariff 2025 (fra 01.01.2025)
                                        </ListGroup.Item>
                                    </ListGroup>
                                </li>
                                <li>
                                    <ListGroup defaultActiveKey="#link1">
                                        <ListGroup.Item action href='/pdf/Tariffhefte01052024.pdf'>
                                            Tariff 2024 (etter 01.05.2024)
                                        </ListGroup.Item>
                                    </ListGroup>
                                </li>
                            </ul>
                            <ul>
                                <div>
                                    Nettselskapene fastsetter nettleien til sine kunder av nettet i sitt
                                    konsesjonsområde på bakgrunn av en inntektsramme fastsatt av NVE/RME.
                                </div>
                                <div>
                                    <a href="https://www.nve.no/reguleringsmyndigheten/regulering/nettvirksomhet/nettleie/"
                                       target="_blank">
                                        https://www.nve.no/reguleringsmyndigheten/regulering/nettvirksomhet/nettleie/
                                    </a>
                                    <br/>
                                    <a href="https://www.nve.no/reguleringsmyndigheten/kunde/nett/nettleie/"
                                       target="_blank">
                                        https://www.nve.no/reguleringsmyndigheten/kunde/nett/nettleie/
                                    </a>
                                </div>
                                <div>
                                    Når et nettselskap tar inn for mye nettleie på ett år, blir dette en merinntekt, tar en inn for lite blir det mindreinntekt.
                                    Mer/mindre saldo sørger for at kundene over tid betaler riktig nivå til sitt nettselskap.

                                    Det kan variere fra år til år hvor godt en treffer med nett tariffene balansert mot inntektsramme satt av NVE/RME men vi styrer mot en balanse mellom disse.

                                    Grunnlaget for endring i tariffer er gitt inntektsramme, eiendomsskatt, kostnader overliggende nett, diverse gebyrer og offentlige avgifter.
                                    Avhengig av hvordan utviklingen av det generelle kostnadsbildet blir, kan det bli behov for ytterligere justeringer av tariffene.
                                    Men vi vil forsøke å holde en så jevn tariff som mulig
                                </div>
                            </ul>
                            <h1>Standardavtale om nettleie og vilkår for tilknytning
                            </h1>
                            <ul>
                                <li>
                                    <ListGroup defaultActiveKey="#link1">
                                        <ListGroup.Item action
                                                        href='/pdf/2021-01-14-standard-vilkar-for-nettleie-og-tilknytning-forbruker-jan-2021.docx'>
                                            Standardavtale
                                        </ListGroup.Item>
                                    </ListGroup>
                                </li>
                            </ul>
                            <h1>Strømstøtte
                            </h1>
                            <ul>
                                <li>
                                    Fra 1/9 er det ny timesbasert strømstøtte.
                                    <ListGroup defaultActiveKey="#link1">
                                        <ListGroup.Item action href='https://www.nve.no/reguleringsmyndigheten/kunde/stroem/spoersmaal-og-svar-om-stroemstoette-for-hoeye-stroempriser/'>
                                            NVE-strømstøtte
                                        </ListGroup.Item>
                                    </ListGroup>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
